<template>
    <footer class="footer_block">
      <div class="container">
        <div class="footer_box">
          <div class="row">
            <div class="col-md-6 col-12">
              <h3>Let’s Keep In Touch</h3>
              <p class="mb-0" v-if="$country.is_india">
                Join our exclusive mailing list and receive special offers, updates,
                <br />
                and the latest launches straight to your inbox.
              </p>
              <p class="mb-0" v-else>
                Join our exclusive mailing list and receive special offers, updates,
                <br />
                and latest launches straight to your inbox.
              </p>
              <div class="row my-4">
                <div class="col-lg-8 col-md-10 col-12">
                  <CommonSubscribePopup />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10 col-md-12 col-12">
                  <h3><router-link :to="localePath('/contact-us')">Contact</router-link></h3>
                  <ul class="contact_block">
                    <li>
                      <a rel="nofollow" :href="'tel:' + $country.mobile">
                        <SvgHeaderSvg :type="'telephone'" />
                        <span class="ms-2">Phone</span></a
                      >
                    </li>
                    <li>
                      <a rel="nofollow noopener" :href="'mailto:' + $country.email">
                        <SvgHeaderSvg :type="'email'" />
                        <span class="ms-2">Email</span></a
                      >
                    </li>
                    <li class="d-flex align-items-center" @click="openTawkChat">
                      <SvgHeaderSvg :type="'chat'" />
                      <span class="ms-2">Chat</span>
                    </li>
                    <li>
                      <router-link :to="localePath('/appointment')">
                        <SvgHeaderSvg :type="'virtual'" />
                        <span class="ms-2"> Book appointment</span>
                      </router-link>
                    </li>
                  </ul>
                  <a
                    v-if="$country.bbb"
                    rel="noopener noreferrer nofollow"
                    href="https://www.bbb.org/us/ny/new-york/profile/jewelry-stores/friendly-diamonds-inc-0121-87151940"
                    target="_blank"
                  >
                    <picture>
                      <source width="200" height="62" type="image/webp" srcset="@/assets/footer/bbb.webp" />
                      <img
                        loading="lazy"
                        width="200"
                        height="62"
                        type="image/jpeg"
                        src="@/assets/footer/bbb.jpg"
                        alt="BBB Accredited Business"
                      />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <h3><router-link :to="localePath('/customer-service')">Services</router-link></h3>
              <ul>
                <li><router-link :to="localePath('/customer-service/shipping-policy')">Free shipping </router-link></li>
                <li v-if="$country.is_india">
                  <router-link :to="localePath('/customer-service/return-policy')">15 Day Returns </router-link>
                </li>
                <li v-else>
                  <router-link :to="localePath('/customer-service/return-policy')">{{ $country.returnPeriod }} Day Returns </router-link>
                </li>
                <li><router-link :to="localePath('/customer-service/life-time-warranty')">Lifetime warranty </router-link></li>
                <li v-if="!$country.is_india"><router-link :to="localePath('/customer-service/exchange-policy')">Lifetime Trade-In </router-link></li>
                <li><router-link :to="localePath('/customer-service/ring-engraving')">Free engraving </router-link></li>
                <li><router-link :to="localePath('/customer-service/ring-resizing')">Free Ring resizing </router-link></li>
                <li><router-link :to="localePath('/customer-service/payment-options')">Payment options</router-link></li>
                <li v-if="$country.is_india">
                  <router-link :to="localePath('/customer-service/exchange-buyback-policy')">Exchange & Buyback Policy</router-link>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-12">
              <h3>About us</h3>
              <ul>
                <li><router-link :to="localePath('/our-story')">Our Story </router-link></li>
                <li><router-link :to="localePath('/ethical-and-conflict-free-diamonds')">Ethical & Conflict-free diamonds </router-link></li>
                <li><router-link :to="localePath('/testimonials')">Testimonials</router-link></li>
                <li><router-link :to="localePath('/blog')">Blog</router-link></li>
                <li><router-link :to="localePath('/press')">Press </router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="copyright_block" >
          <h5 class="font-active mb-2"><router-link :to="localePath('/')">Friendly Diamonds</router-link></h5>
          <p class="mb-0">
            ©{{ new Date().getFullYear() }} Friendly Diamonds. All Rights Reserved |
            <router-link :to="localePath('/terms-of-service')">Terms & Conditions</router-link> |
            <router-link :to="localePath('/privacy-policy')">Privacy Policy</router-link> |
            <router-link :to="localePath('/cookie-policy')">Cookie Policy</router-link> |
            <router-link :to="localePath('/sitemap')">Sitemap</router-link>
          </p>
          <div class="social_block" :class="{'new_block': isNewBlock, 'social_block': isSocialBlock}">
            <ul class="ps-0 mb-0">
              <li>
                <a @click="socialClick('facebook')" rel="nofollow noopener" target="_blank" href="https://www.facebook.com/FriendlyDiamonds">
                  <SvgFooterSvg :type="'facebook'" />
                </a>
              </li>
              <li>
                <a @click="socialClick('twitter')" rel="nofollow noopener" target="_blank" href="https://twitter.com/FriendlyDiam">
                  <SvgFooterSvg :type="'twitter'" />
                </a>
              </li>
              <li>
                <a @click="socialClick('instagram')" rel="nofollow noopener" target="_blank" :href="$country.instagramHandle">
                  <SvgFooterSvg :type="'instagram'" />
                </a>
              </li>
              <li>
                <a @click="socialClick('linkedin')" rel="nofollow noopener" target="_blank" href="https://www.linkedin.com/company/friendly-diamonds">
                  <SvgFooterSvg :type="'linkedin'" />
                </a>
              </li>
              <li>
                <a @click="socialClick('youtube')" rel="nofollow noopener" target="_blank" href="https://www.youtube.com/@friendly.diamonds">
                  <SvgFooterSvg :type="'youtube'" />
                </a>
              </li>
              <li>
                <a @click="socialClick('pinterest')" rel="nofollow noopener" target="_blank" href="https://www.pinterest.com/friendly_diamonds/">
                  <SvgFooterSvg :type="'pinterest'" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="pay_type for_desktop" v-if="$country.footer_pay_icons">
          <div class="pay_type_box pay_type_visa"></div>
          <div class="pay_type_box pay_type_master"></div>
          <div class="pay_type_box pay_type_american"></div>
          <div class="pay_type_box pay_type_discover"></div>
          <div class="pay_type_box pay_type_paypal"></div>
          <div class="pay_type_box pay_type_affirm"></div>
          <div class="pay_type_box pay_type_bank"></div>
          <div class="pay_type_box pay_type_splitit"></div>
          <div class="pay_type_box pay_type_afterpay"></div>
        </div>
      </div>
    </footer>
  </template>
  
<script setup>
    const route = useRoute()

    const localePath = useLocalePath()
    const { $country, $socialGtag } = useNuxtApp()
    
    function socialClick(type){
        $socialGtag(type)
    }

    const moduleType = computed(() => route.meta.moduleType);
    const moduleSubType = computed(() => route.meta.moduleSubType);


    // if name is added here then new block should be applied 
    const moduleSubtypes = [
      '/ring-settings','cyo-setting-detail-ring','cyo-setting-list-ring','cyo-complete-ring','cyo-complete-earring', 'preset', 'shop', 'in-stock-ring', 
      'in-stock-pendant','band-ring','full-eternity-ring', 'stud-earring','in-stock-earring', 'anniversary-ring', 'couple-bands-ring', 'couple-rings',
      'tennis-bracelet','fashion-bracelet','fashion-earring','fashion-necklace', 'hoop-earring', 'toi-et-moi-ring', 'recently-purchase-all',
      'shop-womens-wedding-rings','loose-listing','cyo-diamond-list-ring','loose-detail','hues-rings','soiree-rings',
      'shop-all','solitaire-necklace','tennis-necklace','cyo-diamond-detail-ring','cyo-diamond-detail-earring',
      'cyo-setting-list-earring','cyo-setting-detail-earring','/in/checkout/address','/in/checkout/payment',
      '/pendant-settings', 'cyo-diamond-list-pendant','cyo-setting-detail-pendant',
      '/cart','/in/cart','/checkout/address','/checkout/payment',
      'in/checkout/address','in/checkout/payment'
    ];

      // if name is added here then social block should be applied 
      const dynamicPaths = [];

      const isSocialBlock = computed(() => {
        const routeName = route.meta.name || '';
        // console.log(routeName,'social');
          return dynamicPaths.includes(routeName);
      });

        const isNewBlock = computed(() => {
          const validModuleSubType = moduleSubType.value || '';
          const validModuleType = route.fullPath || '';
          const validRouteModule = route.meta.module || '';
          const routeName = route.meta.name || '';
          const fullPath = route.fullPath || '';

          return moduleSubtypes.includes(validModuleSubType) || 
                moduleSubtypes.includes(validModuleType) || 
                moduleSubtypes.includes(validRouteModule) || 
                moduleSubtypes.includes(routeName) || 
                moduleSubtypes.includes(fullPath);
          });


          // watch(
          //     [moduleType, moduleSubType, () => route.meta.module, () => route.meta.name],
          //     ([newModuleType, newModuleSubType, newRouteModule, newRouteName], [oldModuleType, oldModuleSubType, oldRouteModule, oldRouteName]) => {
          //       // console.log('moduleType changed from', oldModuleType, 'to', newModuleType);
          //       // console.log('moduleSubType changed from', oldModuleSubType, 'to', newModuleSubType);
          //       // console.log('route.meta.module changed from', oldRouteModule, 'to', newRouteModule);
          //       // console.log('route.meta.name changed from', oldRouteName, 'to', newRouteName);
          //     },
          //   );
        
          const openTawkChat = () => {
            try {
              if (import.meta.client && window.Tawk_API) {
                window.Tawk_API.toggle();
            }
            } catch (error) {
              console.log(error);
            }
        };

</script>

<style scoped>
/* /////////payment bakground image */
.pay_type{
    margin-bottom: 2.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pay_type_box {
    margin: 0 7px;
    filter: grayscale(0.9);
    cursor: pointer;
}
.pay_type_box:hover{
    filter: none;
}
.pay_type_visa {
    width: 48px;
    height: 30px;
    background: url('../../assets/footer/payment_option.webp') -12px -6px;
}
.pay_type_master {
    width: 49px;
    height: 32px;
    background: url('../../assets/footer/payment_option.webp') -82px -5px;
}
.pay_type_american {
    width: 47px;
    height: 31px;
    background: url('../../assets/footer/payment_option.webp') -154px -5px;
}
.pay_type_discover {
    width: 48px;
    height: 32px;
    background: url('../../assets/footer/payment_option.webp') -225px -5px;
}
.pay_type_paypal {
    width: 67px;
    height: 31px;
    background: url('../../assets/footer/payment_option.webp') -296px -7px;
}
.pay_type_affirm {
    width: 58px;
    height: 27px;
    background: url('../../assets/footer/payment_option.webp') -387px -10px;
}
.pay_type_bank {
    width: 58px;
    height: 25px;
    background: url('../../assets/footer/payment_option.webp') -467px -7px;
}
.pay_type_splitit {
    width: 52px;
    height: 28px;
    background: url('../../assets/footer/payment_option.webp') -565px -4px;
}
.pay_type_afterpay{
    width: 121px;
    height: 24px;
    background: url('../../assets/footer/payment_option.webp') -640px -8px;
}




/* /////////payment bakground image */

footer.footer_block {
    border-top: 1px solid #e2e2e2;
    margin-top: 2%;
    padding: 3% 0 0 0;
}
.footer_box h3 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    margin-bottom: 20px;
}

.footer_box p {
    text-align: left;
    color: #6a6161;
    font-size: 14px;
    font-weight: 500;
}
a{
    color: #000;
}
.footer_box ul {
    padding-left: 0;
}

.footer_box ul li {
    list-style: none;
    color: #6a6161;
}

.footer_box ul li  a {
    color: #6a6161;
    font-size: 16px;
    display: flex;
    padding: 5px 0;
    align-items: center;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
ul.contact_block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

ul.contact_block li {
    width: 40%;
    margin-bottom: 2%;
    cursor: pointer;
}
.footer_box {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 2%;
}
.copyright_block {
    padding: 3% 0 0;
}

.copyright_block h5 {
    text-align: center;
    font-size: 18px;
    display: block;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: normal;
    color: #000;
}

.social_block ul li {
    list-style: none;
    display: inline-block;
    text-align: center;
    margin: 0 8px;
}

.social_block ul {
    text-align: center;
   
}
.social_block ul li a {
    font-size: 20px;
    color: #000;
}

.social_block p {
    text-align: center;
}

.copyright_block p {
    text-align: center;
    color: #6a6161;
    font-size: 16px;
    line-height: 1.5;
}
.copyright_block p a{
    color: #6a6161;
    font-size: 16px;
    line-height: 1.5;
}
.social_block {
    margin: 2% 0;
    
}

@media(max-width: 1200px){
    ul.contact_block li {
        width: 50%;
    }
}
@media(max-width: 767px){
  
    .new_block{
      padding-bottom: 70px
    }
    .copyright_block p a {
        font-size: 14px;
        line-height: 1.8;
    }
    .copyright_block h5{
        font-size: 18px;
    }
    .copyright_block p{
        font-size: 14px;
        line-height: 1.8;
        padding: 1% 0 4% 0;
    }
    .footer_box ul {
        padding-left: 0;
        column-count: 2;
    }
    .footer_box h3 {
        margin: 20px 0 10px;
    }
    .copyright_block {
        padding: 9% 0 5%;
    }
    .pay_type {
        flex-wrap: wrap;
       
        margin: 0 auto;
        padding-bottom: 20px;
    }
    .pay_type_box {
        margin: 0;
        filter: none;
        transform: scale(0.8);
    }
}

</style>
