<template>
    <section class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6">
                <div class="img_box">
                    <img src="/assets/valentines/secretlove.jpg" class="radious_eight" width="1527" height="1077"
                        alt="serect to love code">

                    <p class="terms-and-condition" @click="openCus">
                        <span>*T&C APPLIED</span>
                    </p>
                </div>

            </div>
            <div class="col-12 col-md-6">

                <div class="secret_love">
                    <div class="secret_new">
                        <h4 class="mb-0">
                            Unlock The
                        </h4>

                        <div class="secret_svg secret_hide_desk">
                            <img src="/assets/valentines/secrettolove_web.svg" width="235" height="71"
                                alt="secret to love">
                        </div>
                        <div class="secret_svg secret_hide_mob">
                            <img src="/assets/valentines/secrettolovemob.svg" width="208" height="63"
                                alt="secret to love">
                        </div>

                        <p>
                            Get an exclusive discount code this Valentine’s!
                        </p>

                    </div>
                    <div class="get_shop">
                        <div class="get_code">
                            <span @click=openValentine>
                                Unlock Discount Code
                            </span>
                        </div>

                        <div class="shop_vale mt-3">
                            <NuxtLink :to="localePath('/valentines-day-jewelry')">
                                Shop Valentine's Day {{ $t('jewelry') }}
                            </NuxtLink>
                        </div>
                    </div>

                    <CommonCustomModal v-model="valentine" :size="'lg'" :no_paddVal="true">
                        <ValentinesSubscribeModal @hideModal="hideModal" />
                    </CommonCustomModal>


                    <CommonCustomModal v-model="flagShowModal" :size="'lg'" :no_padd="true">
                        <ModalTermsAndConditionsBF />
                    </CommonCustomModal>
                </div>

            </div>
        </div>
    </section>
</template>

<script setup>
const flagShowModal = ref(false);
const openCus = () => {
    flagShowModal.value = true;
};
const valentine = ref(false);
const openValentine = () => {
    valentine.value = true;
};
const hideModal = () => {
    valentine.value = false;
};

</script>


<style scoped>
.img_box {
    position: relative;
}

.terms-and-condition {
    color: #909090;
    letter-spacing: 1px;
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    z-index: 1;
}

.secret_love h4 {

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;

}

.secret_love {
    margin-left: 15%;
}

.secret_svg {

    margin: 10px 0;

}

.secret_love p {

    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 24px;
    color: #353535;
    text-transform: uppercase;

}

.get_code {
    padding: 14px 30px;
    border-radius: 4px;
    max-width: 300px;
    min-width: 300px;
    border: 1.5px solid #212529;
    text-align: center;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.shop_vale {
    max-width: 350px;
    min-width: 350px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-align: center;
    color: #212529;
    text-transform: uppercase;
}

.shop_vale a {
    padding: 14px 30px;
    border-radius: 4px;
    color: #212529;
    border: 1.5px solid #212529;
    text-align: center;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.get_code span {

    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-align: center;
    color: #212529;
    text-transform: uppercase;

}

.shop_vale span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-align: center;
    color: #212529;
    text-transform: uppercase;
}

.secret_hide_mob {
    display: none;
}

.secret_hide_desk {
    display: block;
}

@media (max-width : 991px) {
    .secret_love br {
        display: none;
    }

    .secret_love {

        /* text-align: center; */
        margin: 24px 0;

    }

    .secret_hide_desk {
        display: none;
    }

    .secret_hide_mob {
        display: block;
    }

    .secret_new {
        margin: 20px 0;
    }

    .secret_love br {

        display: none;

    }

    .secret_svg {
        margin: 16px 0;
    }

    .secret_love h4 {

        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.5px;
        /* text-align: center; */

    }

    .secret_love p {
        color: #353535;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.5px;
        /* text-align: center; */
        margin: 0;
    }

    .get_code,
    .shop_vale {
        /* margin: 0 auto; */
    }
}

@media (max-width : 767px) {

    .secret_love,
    .secret_love h4,
    .secret_love p {
        text-align: center;
    }

    .shop_vale {
        margin: 0 auto;
        max-width: 330px;
        min-width: 330px;
    }

    .get_code {
        min-width: 280px;
        max-width: 280px;
        margin: 0 auto;
    }
}
</style>