<template>
  <div>
    <div>
      <div id="teleports"></div>
    </div>
    <div>
      <NuxtLoadingIndicator :color="'#D59066'" />
    </div>
    <div>
      <CommonTheHeader v-if="!['hues-ring-landing___us', 'hues-ring-landing___in'].includes(route.name)" />
      <div>
        <slot />
      </div>
      <CommonTheFooter v-if="!['hues-ring-landing___us', 'hues-ring-landing___in'].includes(route.name)" />
    </div>
    <div>
      <SpeedInsights/>
    </div>
    <client-only>
        <CommonCookiePopup />
        <CommonSubscribeModal v-if="!$country.is_india && !['hues-ring-landing___us', 'hues-ring-landing___in'].includes(route.name)" />
        <div class="wtsapp_fixed">
            <a v-if="$country.is_india" target="_blank" rel="nofollow" href="https://wa.me/9920987654">
                <img src="~/assets/assets/wtsapp.svg" width="50" height="50" alt="">
            </a>
        </div> 
    </client-only>
  </div>
</template>

<script setup>
import { useSeo } from '@/composables/seo/setMetaTags'
import { SpeedInsights } from "@vercel/speed-insights/nuxt"
const route = useRoute()

const { $country } = useNuxtApp()

const tags = useSeo();
await tags.globalMetatags()


</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
.wtsapp_fixed {
      position: fixed;
      bottom: 25px;
      right: 25px;
      z-index: 9999999;
  }
  @media(max-width: 767px){
      .wtsapp_fixed img {
          width: 45px;
      }
      .wtsapp_fixed {
          bottom: 20px;
          right: 20px;
      }
  }
</style>
