<template>
    <div class="">
        <h1 class="mb-4 text-center font-active">Shop Rings By Style</h1>

        <!-- replaced transition with div, Navigation inbuilt works navigation: false ,SwiperNavigation commenting arrows  v-show="!skeleton"-->
        <div>
            <div >
                <swiper ref="c1"
                :modules="[SwiperAutoplay]"
                :loop="true"
                :loopAdditionalSlides="0"
                :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                  }"
                :breakpoints="{
                    365: {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        centeredSlides : true,
                    },
                    512: {
                        slidesPerView: 2,
                        slidesPerGroup: 1,
                        centeredSlides : false,
                        autoplay:{
                            delay: 1500,
                            disableOnInteraction: true,
                          }
                    },
                    993 : {
                        slidesPerView: 5,
                        slidesPerGroup: 1,
                    },
                }"
                >
                    <swiper-slide>
                        <div class="slide_box">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'halo-engagement-rings' } })">
                                <picture>
                                <source width="280" height="280" type="image/webp" srcset="~/assets/home/style/halo.webp">
                                <img class="thumb" loading="lazy" type="image/jpeg" width="280" height="280" src="~/assets/home/style/halo.jpg" alt="Halo">
                                </picture>
                                <span>Halo</span>
                            </NuxtLink>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_box">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'vintage-engagement-rings' } })">
                                <picture>
                                <source width="280" height="280" type="image/webp" srcset="~/assets/home/style/vintage.webp">
                                <img class="thumb" loading="lazy" type="image/jpeg" width="280" height="280" src="~/assets/home/style/vintage.jpg" alt="Vintage">
                                </picture>
                                <span>Vintage</span>
                            </NuxtLink>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_box">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'solitaire-engagement-rings' } })">
                                <picture>
                                <source width="280" height="280" type="image/webp" srcset="~/assets/home/style/solitaire.webp">
                                <img class="thumb" loading="lazy" type="image/jpeg" width="280" height="280" src="~/assets/home/style/solitaire.jpg" alt="Solitaire">
                                </picture>
                                <span>Solitaire</span>
                            </NuxtLink>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_box">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'three-stone-engagement-rings' } })">
                                <picture>
                                <source width="280" height="280" type="image/webp" srcset="~/assets/home/style/three_stone.webp">
                                <img class="thumb" loading="lazy" type="image/jpeg" width="280" height="280" src="~/assets/home/style/three_stone.jpg" alt="Three Stone">
                                </picture>  
                                <span>Three Stone</span>
                            </NuxtLink>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide_box">
                            <NuxtLink :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'side-stone-engagement-rings' } })">
                                <picture>
                                <source width="280" height="280" type="image/webp" srcset="~/assets/home/style/side_stone.webp">
                                <img class="thumb" loading="lazy" type="image/jpeg" width="280" height="280" src="~/assets/home/style/side_stone.jpg" alt="Side Stone">
                                </picture>
                                <span>Side Stone</span>
                            </NuxtLink>
                        </div>
                    </swiper-slide>

                  
                </swiper> 
                
                <!-- <div class="arrow_block">
                    <div class="slick_arrow_up mr-2" @click="showNext"><span></span></div>
                    <div class="slick_arrow_down ml-2" @click="showPrev"><span></span></div>
                </div> -->
            </div>
           
        </div>
    </div>
</template>

<script setup>
 const localePath = useLocalePath()
const props =  defineProps(['locale'])

</script>



<style scoped>
 /* --------------  span{
      changed arrow css from -40 to 40px & span display
    }*/
.img_box{
    overflow: hidden;
    max-width: 280px;
    max-height: 280px;
}
img{
    transition: 0.5s;
}
.slide_box:hover img{
    transform: scale(1.1);
    transition: 0.5s;
}
.slide_box:hover span{
    color: var(--green);
}
.slide_box{
    text-align: center;
    position: relative;
}
h1{
    font-size: 30px;
}
p{
    font-weight: 500;
    color: #6a6161;
    padding: 0 4%;
}
a{
    display: inline-block;
}
span{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    text-align: center;
    color: #000;
    padding: 5% 0;
    letter-spacing: 1.5px;
}
.arrow_block{
    display: none;
}
@media(max-width: 767px){
    h1{
        font-size: 24px;
    }

    span{
    font-size: 14px;
}
   

}
@media(max-width: 512px){
    /* -------------- */
    .arrow_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
    }
    .slick_arrow_up, .slick_arrow_down {
        position: relative;
        border: 0;
        height: 4px;
        width: 62px;
        margin-top: 40px;
    }
    .slick_arrow_up:before {
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background: #e2e2e2;
        top: 0;
        left: 25px;
    }
    .slick_arrow_up:after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        top: -2px;
        left: 25px;
        border-top: 2px solid #e2e2e2;
        border-right: 2px solid #e2e2e2;
        transform: rotate(-135deg);
    }
    .slick_arrow_down:before {
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background: #e2e2e2;
        top: 0px;
        right: 25px;
    }
    .slick_arrow_down:after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        top: -2px;
        right: 25px;
        border-top: 2px solid #e2e2e2;
        border-right: 2px solid #e2e2e2;
        transform: rotate(45deg);
    }
    /* --------------  span{
        display: none;
    }*/
   
    .slick-slide.slick-active.slick-center.slick-current span {
        display: block;
    }

}


</style>
