<template>
    <section>
        <div class="free_flex">
            <div class="left_flex for_desktop">
                <img width="545" height="440" src="~/assets/free-product/stud.png" alt="">
            </div>
            <div class="center_flex">
                <div>
                    <h3>Diamonds On Us!</h3>
                    <p class="mb-1 for_desktop">Free 1/2 carat gold Solitaire Necklace on a spend of $3000 or more.</p>
                    <p class="mb-0 for_desktop">Free 1 carat gold Stud Earrings on a spend of $5000 or more.</p>
                    <p class="for_mobile">Free Diamond Jewelry On Purchases Over $3000!</p>
                </div>
            </div>
            <div class="right_flex">
                <img width="545" height="440" src="~/assets/free-product/pendant.png" alt="">
                <img class="for_mobile" width="545" height="440" src="~/assets/free-product/stud.png" alt="">
            </div>
        </div>

        <div class="free_popup_block">
            <div class="popup_btn" @click="showModal">See Terms & Conditions</div>
            <CommonCustomModal v-model="flagShowModal" :size="'xl'">
                <FreeProductTerms />
            </CommonCustomModal>
            
        </div>
        
    </section>
</template>

<script setup>
    
    const flagShowModal = ref(false);
    const showModal = () => {
        flagShowModal.value = true;
    };
    onMounted( () => {

    })

 


</script>


<style scoped>
/* Two Changes done .modal-header, modal-body & color to h5 */
section {
    background-image: url('/assets/free-product/bg_desktop.jpg');
    position: relative;
}
.free_flex {
    display: flex;
    justify-content: space-around;
    padding: 0 15%;
}

.left_flex {
    width: 20%;
}

.right_flex {
    width: 20%;
}

.center_flex {
    width: 60%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
h3 {
    font-size: 40px;
    color: #fee4b3;
    text-transform: uppercase;
    letter-spacing: 5px;
}

p {
    color: #fff;
    font-size: 18px;
}
p span {
    font-weight: 600;
}
.free_popup_block {
    position: absolute;
    bottom: 10px;
    right: 16px;
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
}

.popup_btn {
    cursor: pointer;
    font-size: 10px;
    color: #c4c4c4;
}

/* ---------------- */
.title_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal_body p {
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 9px;
    color: #6a6161;
}

.title_block span {
    cursor: pointer;
}
.modal_body p span {
    font-weight: 600;
    color: #212529;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}
h5{
    font-size: 24px;
    text-align: center;
    color: #212529;
}

.largesvg {
    transform: scale(1.3);
    }

.modal-content{
    padding: 2%;
}

/* ---------------- */
@media(max-width: 1380px){
    h3{
        font-size: 26px;
    }
    p{
        font-size: 16px;
    }
}

@media(max-width: 1200px){
    .free_flex{
        padding: 0 5%;
    }
    
}

@media(max-width: 992px){
    h3{
        font-size: 20px;
        letter-spacing: 3px;
    }
    p{
        font-size: 14px;
    }
    .free_flex{
        padding: 0 1%;
    }
}
@media(max-width: 767px){
    section {
        padding-bottom: 8px;
    }
    .free_flex {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .left_flex {
        width: 20%;
    }

    .right_flex, .center_flex {
        width: 100%;
    }
    .right_flex{
        display: flex;
    }
    .right_flex img{
        width: 50%;
    }
    h3{
        font-size: 26px;
    }
    p{
        font-size: 16px;
    }
    h5{
        font-size: 16px;
    }
    .free_popup_block {
        position: relative;
        width: 100%;
        text-align: center;
        bottom: 5px;
        right: 0;
    }
}
</style>