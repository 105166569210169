<template>
    <section class="new_arrival_products">
        <div class="container-fluid" v-if="skeleton">
            <div class="row align-items-center">
                <div class="col-md-none col-lg-4 mb-2">
                    <div class="flex-lefty new">
                    <h3 class="mb-2 font-active">New and Noteworthy</h3>

                    <p class="mb-4 for_desktop">Discover timeless treasures that elevate your style.
                        Shop the latest designs now!</p>
                    <NuxtLink class="for_desktop" :to="localePath({ name: 'shop-all' })">
                        <div class="button">
                            <span>SHOP NOW</span>
                        </div>
                    </NuxtLink>
                </div>
                </div>
                <div class="col-9 col-sm-7 col-md-4 col-lg-3 step_skeleton">
                    <div class="slide_box">
                        <NuxtLink :to="localePath({ name: 'shop-fashion-earring' })">
                            <div class="img-container">
                                <picture>
                                    <source width="1108" height="1760" type="image/jpeg"
                                        srcset="~/assets/black_friday/new_arrivals/earring.jpg">
                                    <source width="1108" height="1760" type="image/webp"
                                        srcset="~/assets/black_friday/new_arrivals/earring.webp">

                                    <img loading="lazy" type="image/jpeg" width="1108" height="1760"
                                        src="~/assets/black_friday/new_arrivals/earring.webp" alt="Fashion earrings">
                                </picture>
                            </div>
                            <span>Fashion earrings</span>
                        </NuxtLink>
                        <div class="valentines_chip">
                            <div class="flex">
                                <div class="img-container">
                                    <img src="~/assets/valentines/heart_chip.svg" alt="discount image" width="21"
                                        height="21" />
                                </div>
                                <p class="offer-text">UPTO 25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade" v-else>
            <div class="arrival-container">
                <div class="flex-left">
                    <h3 class="mb-2 font-active">New and Noteworthy</h3>

                    <p class="mb-4 for_desktop">Discover timeless treasures that elevate your style.
                        Shop the latest designs now!</p>
                    <NuxtLink class="for_desktop" :to="localePath({ name: 'shop-all' })">
                        <div class="button">
                            <span>SHOP NOW</span>
                        </div>
                    </NuxtLink>
                </div>

                <div class="flex-right">
                    <div>
                        <Swiper v-bind="swiperOptions" @swiper="onSwiperLoad">
                            <SwiperSlide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-fashion-earring' })">
                                        <div class="img-container">
                                            <picture>
                                                <source width="1108" height="1760" type="image/jpeg"
                                                    srcset="~/assets/black_friday/new_arrivals/earring.jpg">
                                                <source width="1108" height="1760" type="image/webp"
                                                    srcset="~/assets/black_friday/new_arrivals/earring.webp">

                                                <img loading="lazy" type="image/jpeg" width="1108" height="1760"
                                                    src="~/assets/black_friday/new_arrivals/earring.webp"
                                                    alt="Fashion earrings">
                                            </picture>
                                        </div>
                                        <span>Fashion earrings</span>
                                    </NuxtLink>
                                    <div class="valentines_chip">
                                        <div class="flex">
                                            <div class="img-container">
                                                <img src="~/assets/valentines/heart_chip.svg" alt="discount image"
                                                    width="21" height="21" />
                                            </div>
                                            <p class="offer-text">UPTO 25%</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-fashion-necklace' })">
                                        <div class="video-container">
                                            <video @contextmenu.prevent="" controlsList="nodownload" width="819"
                                                height="1300" autoplay loop muted playsinline>
                                                <source
                                                    src="https://assets.friendlydiamonds.com/fdl/homepagebanner/arrival_necklace.mp4"
                                                    type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <span>fashion necklace</span>
                                    </NuxtLink>
                                    <div class="valentines_chip">
                                        <div class="flex">
                                            <div class="img-container">
                                                <img src="~/assets/valentines/heart_chip.svg" alt="discount image"
                                                    width="21" height="21" />
                                            </div>
                                            <p class="offer-text">UPTO 35%</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div class="slide_box">
                                    <NuxtLink :to="localePath({ name: 'shop-fashion-bracelet' })">
                                        <div class="img-container">
                                            <picture>
                                                <source width="1108" height="1760" type="image/jpeg"
                                                    srcset="~/assets/black_friday/new_arrivals/bracelet.jpg">
                                                <source width="1108" height="1760" type="image/webp"
                                                    srcset="~/assets/black_friday/new_arrivals/bracelet.webp">

                                                <img loading="lazy" type="image/jpeg" width="1108" height="1760"
                                                    src="~/assets/black_friday/new_arrivals/bracelet.webp"
                                                    alt="Slider Bracelets">
                                            </picture>
                                        </div>
                                        <span>Slider Bracelets</span>
                                    </NuxtLink>

                                    <div class="valentines_chip">
                                        <div class="flex">
                                            <div class="img-container">
                                                <img src="~/assets/valentines/heart_chip.svg" alt="discount image"
                                                    width="21" height="21" />
                                            </div>
                                            <p class="offer-text">UPTO 50%</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                </div>

                <div class="flex-left for_mobile">

                    <p class="mb-4">Discover timeless treasures that elevate your style.
                        Shop the latest designs now!</p>
                    <NuxtLink :to="localePath({ name: 'shop-all' })">
                        <div class="button">
                            <span>SHOP NOW</span>
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </transition>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const skeleton = ref(true);

const swiperOptions = {
    slidesPerGroup: 1,
    spaceBetween: 10,

    breakpoints: {
        0: {
            slidesPerView: 1.5,
            centeredSlides: true,
            centeredSlidesBounds: true,
        },

        768: {
            slidesPerView: 3,
        }
    },
};

const onSwiperLoad = (swiper) => {
    swiper?.slideTo(1)
}
onMounted(() => {
    nextTick(() => skeleton.value = false);
    // skeleton.value = false;
});
</script>

<style scoped>
.step_skeleton {
    position: relative;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.step_skeleton .slide_box {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.arrival-container {
    display: flex;
    align-items: center;
}

.flex-left {
    width: 30%;
    text-align: left;
    margin-right: 5%;
}

.flex-right {
    width: 70%;
}

.img-container,
.video-container {
    overflow: hidden;
    border-radius: 8px;
}

.video-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    pointer-events: none;
}

img,
video {
    transition: 0.5s;
}

.slide_box:hover img,
.slide_box:hover video {
    transform: scale(1.1);
    transition: 0.5s;
}

.slide_box {
    text-align: center;
    position: relative;
}

.slide_box img,
.slide_box video {
    display: block;
}

.slide_box a:hover span {
    color: #000;
}

.slide_box span {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    background: #ffffff60;
    color: #000;
    display: block;
}

.slide_box:hover span {
    color: var(--green);
}

h3 {
    font-size: 30px;
}

a {
    display: block;
}

p {
    font-weight: 500;
    color: #606060;
    font-size: 18px;
    line-height: 26px;
}

span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    text-align: center;
    color: #606060;
    letter-spacing: 1.5px;
    padding: 5px;
}

.button {
    border: 1.5px solid #212529;
    border-radius: 4px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    max-width: 180px;
    cursor: pointer;
}

.button span {
    font-size: 14px;
    color: #212529;
    font-weight: bold;
    letter-spacing: 1.5px;
}

@media (min-width: 767px) and (max-width: 991px) {
  .flex-lefty.new {
    text-align: center;
    margin: 0 auto;
  }
  .flex-lefty.new {
    text-align: center;
}
   h3 {
    text-align: center;
  }
  .new a.for_desktop {
    display: flex
;
    justify-content: center;
}
}


@media(max-width: 767px) {
    
    .flex-left.for_mobile {
        margin-top: 5%;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }

    span {
        font-size: 14px;
    }

    h3 {
        font-size: 24px;
    }
}

@media(max-width: 991px) {
    .flex-left a {
        margin: 0 auto;
        width: 200px;
    }

    .arrival-container {
        flex-direction: column;
    }

    .flex-left,
    .flex-right {
        width: 100%;
        margin: 0;
    }

    .flex-left {
        text-align: center;
        margin-bottom: 5%;
    }
}
</style>
