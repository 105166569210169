function trigerfb(eventName, data){
    try {
        if (typeof fbq === "function") { 
            // call api
            // try {
            //     let currentUrl = window?.location?.href

            //     $fetch(useCountryPath('/send-facebook-meta-event'), {
            //         method:'POST',
            //         body: {eventName,data,currentUrl}
            //     })

            // } catch (error) {
            //     console.log(error,'error')
            // }

            fbq('track', eventName, data)
        }
    } catch (error) {
        
        console.log('error on fbq event', error)
    }
}

function trigerTT(eventName, data){
    try {
     
        if (ttq != undefined && ttq.track) { 

          
            ttq.track(eventName, data)
        }
    } catch (error) {
        
        console.log('error on ttq event', error)
    }
}

function trigerTTIdentity(data){
    try {
        if (ttq != undefined && ttq.identify) { 
            console.log(data.address.email, 'ttq identity triggered')
            ttq.identify({
                "email": data.address.email,
                "phone_number": '+1'+data.address.shipping_phone,
            })
        }
    } catch (error) {
        console.log('error on ttq identity event', error)
    }
}

export const trigerGtagUtils =  (event, data) => {

    const { user, isAuthenticated } = useSanctumAuth();
    
    if(isAuthenticated.value){
        const emailIds = ['poddardiamonds', 'friendlydiamonds', 'etmedialabs']
        const containsKeyword = emailIds.some(email => user.value.email.includes(email));
        if(containsKeyword){
            return
        }
    }
    
    const { $country } = useNuxtApp()
    const currencyCode = $country.currency_sign;
    
    if(event === 'registerGtag'){
        window.dataLayer = window.dataLayer || [];
        if (data.status === 'load') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "start step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": true
            });

            trigerfb('account creation start step 1',{
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "start step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": true
            })
        }
        if (data.status === 'submit') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "submit step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": false
            });

            trigerfb('account creation submit step 1',{
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "submit step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": false
            })
            
        }
        if (data.status === 'complete') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "complete step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": false
            });

            trigerfb('account creation complete step 1',{
                "event": "genEvent",
                "eventCategory": "funnels",
                "eventAction": "complete step 1",
                "eventLabel": "account creation",
                "eventValue": undefined,
                "eventNonInteraction": false
            })


            window.dataLayer.push({
                'event':'form1',
                'enhanced_conversion_data': {
                "email": data.data.email,
                "phone_number": ''
                }
            })
        }
    }

    if(event === 'trigerTTViewContent'){
        trigerTT(eventName, {
            "value": data.price, // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name": data.name // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
    }

    if(event === 'socialGtag'){
        window.dataLayer = window.dataLayer || [];
        
        if (data === 'facebook') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                "eventValue": undefined,
                "eventNonInteraction": false
            });

            trigerfb('https://www.facebook.com/FriendlyDiamonds',{
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.facebook.com/FriendlyDiamonds",
                "eventValue": undefined,
                "eventNonInteraction": false
            })
        }
        if (data === 'twitter') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://twitter.com/FriendlyDiam",
                "eventValue": undefined,
                "eventNonInteraction": false
            });

            trigerfb('https://twitter.com/FriendlyDiam',{
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://twitter.com/FriendlyDiam",
                "eventValue": undefined,
                "eventNonInteraction": false
            })
        }
        if (data === 'instagram') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                "eventValue": undefined,
                "eventNonInteraction": false
            });

            trigerfb('https://www.instagram.com/friendly.diamonds/',{
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.instagram.com/friendly.diamonds/",
                "eventValue": undefined,
                "eventNonInteraction": false
            })
        }
        if (data === 'linkedin') {
            window.dataLayer.push({
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                "eventValue": undefined,
                "eventNonInteraction": false
            });
            trigerfb('https://www.linkedin.com/company/friendly-diamonds',{
                "event": "genEvent",
                "eventCategory": "interactions",
                "eventAction": "click",
                "eventLabel": "https://www.linkedin.com/company/friendly-diamonds",
                "eventValue": undefined,
                "eventNonInteraction": false
            })
        }
    }

    if(event === 'newsletterGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": "newsletter subscription", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false // DO NOT CHANGE
        });

        trigerfb('newsletter subscription submit step 1',{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": "newsletter subscription", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false // DO NOT CHANGE
        })
    }

    if(event === 'choosesettingGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 1", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb( data +" creation",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 1", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'selectsettingGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb(data + " creation",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'choosediamondGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 2", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb(data + " creation",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 2", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'selectdiamondGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 2", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false // DO NOT CHANGE
        });

        trigerfb(data + " creation",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 2", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false // DO NOT CHANGE
        })
    }

    if(event === 'loadcompleteGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 3", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb(data + " creation start step 3",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 3", // DO NOT CHANGE
            "eventLabel": data + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'cyotoCartGtag'){
        let products = [{
            "id": data.setting.id,
            "brand": "friendly diamonds",
            "name": data.setting.activeTitle,
            "variant": data.setting.activeMetal,
            "price": data.setting.activePrice,
            "quantity": 1
        }]
        if (data.moduleSubType != 'earring') {
            products.push({
                "id": data.diamond.id,
                "brand": "friendly diamonds",
                "name": data.diamond.title,
                "variant": `${data.diamond.cut_grade} Cut, ${data.diamond.color} color ,  ${data.diamond.clarity} Clarity`,
                "price": parseFloat(data.diamond.price),
                "quantity": 1
            })
            if(data.merchant_id){
                products.push({
                    "id": data.merchant_id,
                    "brand": "friendly diamonds",
                    "name": data.setting.activeTitle,
                    "variant": data.setting.activeMetal,
                    "price": parseFloat(data.setting.activePrice) + parseFloat(data.diamond.price),
                    "quantity": 1
                })
            }
            
        }
        if (data.moduleSubType === 'earring') {
            products.push({
                "id": data.diamond.id,
                "brand": "friendly diamonds",
                "name": `${data.diamond.weight_one} Total Carat ${data.diamond.shape_one} Lab Grown Diamond`,
                "variant": `${data.diamond.cut_grade_one} Cut, ${data.diamond.color_one} color ,  ${data.diamond.clarity_one} Clarity`,
                "price": parseFloat(data.diamond.price_one),
                "quantity": 1
            }, {
                "id": data.diamond.id,
                "brand": "friendly diamonds",
                "name": `${data.diamond.weight_two} Total Carat ${data.diamond.shape_two} Lab Grown Diamond`,
                "variant": `${data.diamond.cut_grade_two} Cut, ${data.diamond.color_two} color ,  ${data.diamond.clarity_two} Clarity`,
                "price": parseFloat(data.diamond.price_two),
                "quantity": 1
            })
        }
        if (data.appraisal === 1) {
            products.push({
                "id": 999999999,
                "brand": "friendly diamonds",
                "name": "appraisal",
                "variant": "",
                "price": 50,
                "quantity": 1
            })
        }
        //submit step 3
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 3", // DO NOT CHANGE
            "eventLabel": data.moduleSubType + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false
        });

        trigerfb(data.moduleSubType + " creation submit step 3",{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 3", // DO NOT CHANGE
            "eventLabel": data.moduleSubType + " creation", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false
        })

    
        //add to cart push prodcts to datalayer 
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.total),
                "add": {
                    "actionField": {
                        "list": data.moduleSubType + " complete page"
                    },
                    "products": products
                }
            }
        });

        trigerfb('Add to cart ',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "Add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.total),
                "add": {
                    "actionField": {
                        "list": data.moduleSubType + " complete page"
                    },
                    "products": products
                }
            }
        })

        trigerTT('AddToCart', {
            "value": parseFloat(data.total), // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.setting.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name": data.setting.activeTitle // string. The name of the page or product. Example: "shirt".
                }
            ]
        })

    }

    if(event === 'looseImpressionGtag'){
        let listing = []
        data.filter((item) => { 
            if (item.length > 0) {
                listing.push({
                    "id": item.id,
                    "brand": "friendly diamonds",
                    "name": item.title,
                    "variant": `${item.cut_grade} Cut, ${item.color} Color, ${item.clarity} Clarity,  LotNo. ${item.lot}`,
                    "category": "cyo/loose",
                    "price": parseFloat(item.price),
                    "list": "loose diamonds",
                    "position": 1
                })
            }
        })

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression view", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "impressions": listing
            }
        });

        trigerfb('loose diamonds product impression view',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression view", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "impressions": listing
            }
        })
    }

    if(event === 'looseViewGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression click", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "click": {
                    "actionField": {
                        "list": "loose diamonds"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.title,
                        "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(data.price),
                        "list": "loose diamonds",
                        "position": 1
                    }]
                }
            }
        });

        trigerfb('loose diamonds product impression click',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression click", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "click": {
                    "actionField": {
                        "list": "loose diamonds"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.title,
                        "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(data.price),
                        "list": "loose diamonds",
                        "position": 1
                    }]
                }
            }
        })
    }

    if(event === 'loosetoCartGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "add to cart", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "click": {
                    "actionField": {
                        "list": "loose diamonds"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.title,
                        "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        });

        trigerfb('Add to cart',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "Add to cart", // DO NOT CHANGE
            "eventLabel": "loose diamonds", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "click": {
                    "actionField": {
                        "list": "loose diamonds"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.title,
                        "variant": `${data.cut_grade} Cut, ${data.color} Color, ${data.clarity} Clarity,  LotNo. ${data.lot}`,
                        "category": "cyo/loose",
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        })

        trigerTT('AddToCart', {
            "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name": data.title // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
    }

    if(event === 'appointmentInitiateGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb('virtual appointment start step 1',{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "start step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })

    }

    if(event === 'appointmentCreationGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb('virtual appointment submit step 1',{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "submit step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'appointmentSucessGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "complete step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        });

        trigerfb('virtual appointment complete step 1',{
            "event": "genEvent", // DO NOT CHANGE
            "eventCategory": "funnels", // DO NOT CHANGE
            "eventAction": "complete step 1", // DO NOT CHANGE
            "eventLabel": "virtual appointment", // DO NOT CHANGE
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true // DO NOT CHANGE
        })
    }

    if(event === 'removefromCartGtag'){
        let products = []
            products.push(data)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            });

            trigerfb('remove from cart',{
                "event": "genEcom", // DO NOT CHANGE
                "eventCategory": "ecommerce", // DO NOT CHANGE
                "eventAction": "remove from cart", // DO NOT CHANGE
                "eventLabel": "sub-brand", // SAME AS LIST
                "eventValue": undefined, // DO NOT CHANGE
                "eventNonInteraction": false, // DO NOT CHANGE
                "ecommerce": {
                    "currencyCode": currencyCode,
                    "remove": {
                        "actionField": {
                            "list": "category page"
                        },
                        "products": products
                    }
                }
            })
    }

    if(event === 'addressGtag'){
        let products = []
        if (data.items.length) {
            data.items.filter((item) => { 
                products.push(item.gtag)
            })
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 1", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": data.total_label,
                "checkout": {
                    "actionField": {
                        "step": 1
                    },
                    "products": products
                }
            }
        });

        trigerfb('InitiateCheckout',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 1", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": data.total_label,
                "checkout": {
                    "actionField": {
                        "step": 1
                    },
                    "products": products
                }
            }
        })
    
    }

    if(event === 'submitAddressGtag'){
        window.dataLayer.push({
            'event':'form1',
            'enhanced_conversion_data': {
            "email": data.email,
            "phone_number": data.phone.replace(/\s/g, '')
            }
        })
    }

    if(event === 'paymentGtag'){
        let products = []
        let tt_product = []
        if (data.items.length) {
            data.items.filter((item) => { 
                products.push(item.gtag)
            })
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 2", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": data.total_label,
                "checkout": {
                    "actionField": {
                        "step": 2
                    },
                    "products": products
                }
            }
        });

        trigerfb('checkout',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 2", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": data.total_label,
                "checkout": {
                    "actionField": {
                        "step": 2
                    },
                    "products": products
                }
            }
        })

        trigerTT('InitiateCheckout',{
            "value":  data.total_label, // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": tt_product
        })
    }

    if(event === 'paymentTypeGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 3", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "checkout_option": {
                    "actionField": {
                        "step": 3,
                        "option": data
                    }
                }
            }
        });

        trigerfb('checkout',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "checkout", // DO NOT CHANGE
            "eventLabel": "step 3", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "checkout_option": {
                    "actionField": {
                        "step": 3,
                        "option": data
                    }
                }
            }
        })

        trigerTT('AddPaymentInfo', {
            "value": 1, // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
              {
                "content_id": "pay type selection", // string. ID of the product. Example: "1077218".
                "content_type": "product_group", // string. Either product or product_group.
                "content_name": data // string. The name of the page or product. Example: "shirt".
              }
            ]
        })
    }

    if(event === 'paymentSuccessGtag'){
        let products = []
        let tt_products = []
        if (data.items.length) {
            data.items.filter((item) => { 
                products.push(item.gtag)
                tt_products.push(item.tiktok)
            })
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "purchase", // DO NOT CHANGE
            "eventLabel": "webshop", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "purchase": {
                    "actionField": {
                        "id": data.trans_id,
                        "affiliation": "webshop",
                        "revenue": data.total_label,
                        "tax": data.tax_label,
                        "shipping": 0.00,
                        "coupon": undefined,
                        "commission": 'default'
                    },
                    "products": products
                }
            }
        });

        trigerfb('purchase',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "purchase", // DO NOT CHANGE
            "eventLabel": "webshop", // SAME NUMBER AS STEP
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "purchase": {
                    "actionField": {
                        "id": data.trans_id,
                        "affiliation": "webshop",
                        "revenue": data.total_label,
                        "tax": data.tax_label,
                        "shipping": 0.00,
                        "coupon": undefined,
                        "commission": 'default'
                    },
                    "products": products
                }
            }
        })
        
        trigerTTIdentity(data)
        trigerTT('CompletePayment',{
            "value":  data.total_label, // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            // "contents": tt_products
            "contents": [
                {
                  "content_id": "0000", // string. ID of the product. Example: "1077218".
                  "content_type": "product_group", // string. Either product or product_group.
                  "content_name": "data" // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
       

        if (typeof gtag === "function" && currencyCode == 'INR') { 


            gtag('event', 'conversion', {
                'send_to': 'AW-16468839012/AlKuCNKjiaoZEOSU-qw9',
                'value': 1.0,
                'currency': currencyCode,
                'transaction_id': data.trans_id
            });
        
            
        }
    }

    if(event === 'jewelryImpressionGtag'){
        let listing = []
        data.filter((item) => { 

            if( !(item.metals && item.metals.length > 0) ){

                return false
            }

            item.metals.find((list) => { 
                if(list.selected === 1){
                    listing.push({
                        "id": item.id,
                        "brand": "friendly diamonds",
                        "name": item.product_name,
                        "variant": list.name,
                        "category": "jewelry/" + item.sub_type,
                        "price": parseFloat(list.price),
                        "list": item.sub_type,
                        "position": 1
                    })
                }
            })
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression view", // DO NOT CHANGE
            "eventLabel": "category page", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "impressions": listing
            }
        });

        trigerfb('category page',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression view", // DO NOT CHANGE
            "eventLabel": "category page", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "impressions": listing
            }
        })
    }

    if(event === 'selectJewelryGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression click", // DO NOT CHANGE
            "eventLabel": "category page", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "click": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.carats.find((item) => item.selected === 1).id,
                        "brand": "friendly diamonds",
                        "name": data.data.title,
                        "variant": "",
                        "category": "jewelry/" + data.sub_module,
                        "price": parseFloat(data.carats.find((item) => item.selected === 1).price),
                        "list": "category page",
                        "position": 1
                    }]
                }
            }
        });

        trigerfb('category page',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product impression click", // DO NOT CHANGE
            "eventLabel": "category page", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "click": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.carats.id,
                        "brand": "friendly diamonds",
                        "name": data.data.title,
                        "variant": "",
                        "category": "jewelry/" + data.sub_module,
                        "price": parseFloat(data.carats.price),
                        "list": "category page",
                        "position": 1
                    }]
                }
            }
        })
    }

    if(event === 'jewelryDetailGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.carat.id,
                        "brand": "friendly diamonds",
                        "name": data.carat.product_name,
                        "variant": "",
                        "category": "jewelry/" + data.metal.sub_type,
                        "price": parseFloat(data.carat.price)
                    }]
                }
            }
        });

        trigerfb('product detail view',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.carat.id,
                        "brand": "friendly diamonds",
                        "name": data.carat.product_name,
                        "variant": "",
                        "category": "jewelry/" + data.metal.sub_type,
                        "price": parseFloat(data.carat.price)
                    }]
                }
            }
        })
    }
 
    if(event === 'jewelryToCartGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.carat.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.carat.id,
                        "brand": "friendly diamonds",
                        "name": data.metal.product_name,
                        "variant": "",
                        "category": "jewelry/" + data.metal.sub_type,
                        "price": parseFloat(data.carat.price),
                        "quantity": 1
                    }]
                }
            }
        });

        trigerfb('Add to cart',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "Add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.carat.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.carat.id,
                        "brand": "friendly diamonds",
                        "name": data.metal.product_name,
                        "variant": "",
                        "category": "jewelry/" + data.metal.sub_type,
                        "price": parseFloat(data.carat.price),
                        "quantity": 1
                    }]
                }
            }
        })

        trigerTT('AddToCart', {
            "value": parseFloat(data.carat.price), // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.carat.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name":  data.metal.product_name // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
    }
 
    if(event === 'presetDetailGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": "preset-engagement-ring/" + data.sub_type,
                        "price": parseFloat(data.price)
                    }]
                }
            }
        });

        trigerfb('product detail view',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": "preset-engagement-ring/" + data.sub_type,
                        "price": parseFloat(data.price)
                    }]
                }
            }
        })
    }
 
    if(event === 'presetToCartGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": "preset-engagement-ring/" + data.sub_type,
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        });

        trigerfb('Add to cart',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "Add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": "preset-engagement-ring/" + data.sub_type,
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        })

        trigerTT('AddToCart', {
            "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name":  data.product_name // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
    }
 
    if(event === 'bandDetailGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": data.sub_type,
                        "price": parseFloat(data.price)
                    }]
                }
            }
        });

        trigerfb('product detail view',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "product detail view", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": true, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "detail": {
                    "actionField": {
                        "list": "product detail page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": data.sub_type,
                        "price": parseFloat(data.price)
                    }]
                }
            }
        })
    }
 
    if(event === 'bandToCartGtag'){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": data.sub_type,
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        });

        trigerfb('Add to cart',{
            "event": "genEcom", // DO NOT CHANGE
            "eventCategory": "ecommerce", // DO NOT CHANGE
            "eventAction": "Add to cart", // DO NOT CHANGE
            "eventLabel": "sub-brand", // SAME AS LIST
            "eventValue": undefined, // DO NOT CHANGE
            "eventNonInteraction": false, // DO NOT CHANGE
            "ecommerce": {
                "currencyCode": currencyCode,
                "value": parseFloat(data.price),
                "add": {
                    "actionField": {
                        "list": "category page"
                    },
                    "products": [{
                        "id": data.id,
                        "brand": "friendly diamonds",
                        "name": data.product_name,
                        "variant": "",
                        "category": data.sub_type,
                        "price": parseFloat(data.price),
                        "quantity": 1
                    }]
                }
            }
        })

        trigerTT('AddToCart', {
            "value": parseFloat(data.price), // number. Value of the order or items sold. Example: 100.
            "currency": currencyCode, // string. The 4217 currency code. Example: "USD".
            "contents": [
                {
                    "content_id": data.id, // string. ID of the product. Example: "1077218".
                    "content_type": "product_group", // string. Either product or product_group.
                    "content_name":  data.product_name // string. The name of the page or product. Example: "shirt".
                }
            ]
        })
    }
 
}