export default defineNuxtPlugin((nuxtApp) => {
    return{
		provide:{
			formatCarat: (carat) => {
				const removeCt = carat.replace("ct", "");

				if (removeCt.includes(" ")) {
					const [beforeSpace, afterSpace] = removeCt.split(/\s+/);
					const [beforeSlash, afterSlash] = afterSpace.split('/');
		
					return `<div class="format_carat mb-0">
							<p class="mb-0 digit">${beforeSpace}</p>
							<p class="mb-0 fraction">
								<span class="sub">${beforeSlash}</span>
								<span class="slash">/</span>
								<span class="sup">${afterSlash}</span>
							</p>
							</div>`;
				}
		
				if (!removeCt.includes(" ") && removeCt.includes("/")) {
					const [beforeSlash, afterSlash] = removeCt.split('/');
		
					return `<div class="format_carat mb-0">
							<p class="mb-0 fraction">
								<span class="sub">${beforeSlash}</span>
								<span class="slash">/</span>
								<span class="sup">${afterSlash}</span>
							</p>
							</div>`;
				}
		
				return `<div class="format_carat mb-0">
					<p class="mb-0 digit"> ${removeCt}</p>
				</div>`;
			},

			formatExactCarat: (carat) => {
				const number = carat.replace("ct", "");
				let updatedVal = ''


				
				if (number >= 0.01 && number <= 0.03) {
					updatedVal = "1/50";
				  } else if (number >= 0.04 && number <= 0.06) {
					updatedVal = "1/20";
				  } else if (number >= 0.07 && number <= 0.08) {
					updatedVal = "1/15";
				  } else if (number >= 0.09 && number <= 0.11) {
					updatedVal = "1/10";
				  } else if (number >= 0.12 && number <= 0.14) {
					updatedVal = "1/8";
				  } else if (number >= 0.15 && number <= 0.17) {
					updatedVal = "1/6";
				  } else if (number >= 0.18 && number <= 0.22) {
					updatedVal = "1/5";
				  } else if (number >= 0.23 && number <= 0.28) {
					updatedVal = "1/4";
				  } else if (number >= 0.29 && number <= 0.36) {
					updatedVal = "1/3";
				  } else if (number >= 0.37 && number <= 0.38) {
					updatedVal = "3/8";
				  } else if (number >= 0.39 && number <= 0.44) {
					updatedVal = "2/5";
				  } else if (number >= 0.45 && number <= 0.57) {
					updatedVal = "1/2";
				  } else if (number >= 0.58 && number <= 0.63) {
					updatedVal = "5/8";
				  } else if (number >= 0.64 && number <= 0.68) {
					updatedVal = "2/3";
				  } else if (number >= 0.69 && number <= 0.79) {
					updatedVal = "3/4";
				  } else if (number >= 0.80 && number <= 0.94) {
					updatedVal = "7/8";
				  } else if (number >= 0.95 && number <= 1.19) {
					updatedVal = "1";
				  } else if (number >= 1.2 && number <= 1.44) {
					updatedVal = "1 1/4";
				  } else if (number >= 1.45 && number <= 1.69) {
					updatedVal = "1 1/2";
				  } else if (number >= 1.70 && number <= 1.94) {
					updatedVal = "1 3/4";
				  } else if (number >= 1.95 && number <= 2.44) {
					updatedVal = "2";
				  } else if (number >= 2.45 && number <= 2.94) {
					updatedVal = "2 1/2";
				  } else if (number >= 2.95 && number <= 3.44) {
					updatedVal = "3";
				  } else if (number >= 3.45 && number <= 3.94) {
					updatedVal = "3 1/2";
				  } else if (number >= 3.95 && number <= 4.44) {
					updatedVal = "4";
				  } else if (number >= 4.45 && number <= 4.94) {
					updatedVal = "4 1/2";
				  } else if (number >= 4.95 && number <= 5.94) {
					updatedVal = "5";
				  } else if (number >= 5.95 && number <= 6.94) {
					updatedVal = "6";
				  } else if (number >= 6.95 && number <= 7.94) {
					updatedVal = "7";
				  } else if (number >= 7.95 && number <= 8.94) {
					updatedVal = "8";
				  } else if (number >= 8.95 && number <= 9.94) {
					updatedVal = "9";
				  } else if (number >= 9.95 && number <= 10.94) {
					updatedVal = "10";
				  } else if (number >= 10.95 && number <= 11.94) {
					updatedVal = "11";
				  } else if (number >= 11.95 && number <= 12.94) {
					updatedVal = "12";
				  } else if (number >= 12.95 && number <= 13.94) {
					updatedVal = "13";
				  } else if (number >= 13.95 && number <= 14.94) {
					updatedVal = "14";
				  } else if (number >= 14.95 && number <= 15.94) {
					updatedVal = "15";
				  } else if (number >= 15.95 && number <= 16.94) {
					updatedVal = "16";
				  } else if (number >= 16.95 && number <= 17.94) {
					updatedVal = "17";
				  } else if (number >= 17.95 && number <= 18.94) {
					updatedVal = "18";
				  } else if (number >= 18.95 && number <= 19.94) {
					updatedVal = "19";
				  } else if (number >= 19.95 && number <= 20.94) {
					updatedVal = "20";
				  } else if (number >= 20.95 && number <= 21.94) {
					updatedVal = "21";
				  } else if (number >= 21.95 && number <= 22.94) {
					updatedVal = "22";
				  } else if (number >= 22.95 && number <= 23.94) {
					updatedVal = "23";
				  } else if (number >= 23.95 && number <= 24.94) {
					updatedVal = "24";
				  } else if (number >= 24.95 && number <= 25.94) {
					updatedVal = "25";
				  } else {
					updatedVal = number;
				  }
				
				if (updatedVal.includes(" ")) {
					const [beforeSpace, afterSpace] = updatedVal.split(/\s+/);
					const [beforeSlash, afterSlash] = afterSpace.split('/');
		
					return `${beforeSpace} ${beforeSlash}/${afterSlash}`;
				}
		
				if (!updatedVal.includes(" ") && updatedVal.includes("/")) {
					const [beforeSlash, afterSlash] = updatedVal.split('/');
		
					return `${beforeSlash}/${afterSlash}`
				}
		
				return `${updatedVal}`;
			}
		}
		
	}
})
