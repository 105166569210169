<template>
	<teleport to="body">
		<div>
			<div class="" :class="{ 'modal_overlay_open' : modelValue }" @click="closeModal"></div>
			<transition name="modal-slide">
				<div class="modal_body" v-if="modelValue" :class="{  [`modal_${props.size}`]: props.size, 'no_padd': no_padd === true ,'no_paddVal': no_paddVal === true}">
					<div class="modal_content" @click.stop>
						<div class="modal_close_btn" @click="closeModal">
							<SvgHeaderSvg type="close" class="largesvg" />
						</div>
						<slot></slot>
					</div>
				</div>
			</transition>
		</div>
			
	</teleport>
</template>
  
<script setup>

	const router = useRouter();
	const props = defineProps({
		modelValue: {
			type: Boolean,
			required: true,
		},
		size: {
			type: String,
			required: true,
		},
		no_padd: {
			type: Boolean,
		},
		no_paddVal: {
			type: Boolean,
		}
	});
	
  	const emit = defineEmits(["update:modelValue"]);
  
	function closeModal() {
		emit("update:modelValue", false);
	}
	// Watch for changes in modal visibility to toggle body scrolling
	watch(
		() => props.modelValue,
		(newVal) => {
			if (newVal) {
				// document.body.classList.add("filter_open");
				let padding = window.innerWidth - document.documentElement.clientWidth;

				document.body.style.paddingRight = `${padding}px`; 
				document.body.style.overflow = 'hidden'; 

			} else {
				// document.body.classList.remove("filter_open");
				document.body.style.paddingRight = `0px`; 
				document.body.style.overflow = 'auto'; 
			}
		}
	);

	// Close modal on route change
	const handleRouteChange = () => {
		emit("update:modelValue", false);
	};

	onMounted(() => {
		router.beforeEach((to, from, next) => {
			handleRouteChange();
			next();
		});
	});

	onBeforeUnmount(() => {
		document.body.classList.remove("filter_open"); // Clean up if the modal was open
	});

</script>
  
<style scoped>
.modal_close_btn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  z-index: 1;
}

.modal_overlay_open {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0000007a;
  z-index: 9999;
}

.modal_sm {
  	width: 500px;
}

.modal_lg {
    width: 760px;
}

.modal_xl {
    width: 950px;
}
.modal_xxl {
    width: 1100px;
}
.modal_body {
	background: #fff;
	padding: 20px 25px;
	border-radius: 8px;
	position: fixed;
	top: 3%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99999;
	max-height: 95vh;
    overflow: auto;
}

.modal-slide-enter-active {
  	animation: bounce-in 0.5s;
}
.no_padd {
    padding: 0;
}

.no_paddVal{
	padding: 0;
}


@keyframes bounce-in {
	0% {
		top: -10%;
	}
	100% {
		top: 3%;
	}
}
@keyframes bounce-out {
	0% {
		top: -10%;
	}
	100% {
		top: 3%;
	}
}
@media (max-width : 1150px){
	.modal_xxl {
		width: 90%;
	}
}
@media (max-width : 992px){
	.modal_xl {
		width: 90%;
	}
}
@media(min-width: 767px){
	.modal_body::-webkit-scrollbar {
		width: 5px;
		height: 3px;
	}
	.no_paddVal{
		padding: 0;
	}
	/* Track */
	.modal_body::-webkit-scrollbar-track {
		background: #f5f5f5; 
		border-radius: 0 10px 10px 0;
	}
	
	/* Handle */
	.modal_body::-webkit-scrollbar-thumb {
		background: #c4c4c4; 
		border-radius: 8px; 
	}

	/* Handle on hover */
	.modal_body::-webkit-scrollbar-thumb:hover {
		background: #c4c4c4; 
	}
}
@media(max-width: 767px){
	.no_padd {
		padding: 15px;
	}
	.modal-slide-leave-active {
		animation: bounce-out 0.5s;
	}
	.modal_sm, .modal_lg, .modal_xl, .modal_xxl  {
		width: 100% !important;
	}
	.modal_body{
		bottom: 0;
        top: auto;
        border-radius: 8px 8px 0 0;
        max-height: 85vh;
        overflow: auto;
		padding: 15px;
	}
	@keyframes bounce-in {
		0% {
			bottom: -100%;
		}
		100% {
			bottom: 0;
		}
	}
	@keyframes bounce-out {
		0% {
			bottom: 0;
		}
		100% {
			bottom: -100%;
		}
	}
	.modal_body.no_paddVal{
		padding: 0;
	}
}
</style>
