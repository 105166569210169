export function extractRouteName( name ){

    if( name.includes('___') ){

        return name.split("___")[0];  
    }

    return name
}

export function getCouponLabel(discount){
    return `${discount}% OFF`
}

import  { joinURL  } from 'ufo';

export function getFullUrl( path ){

    const domain =  useRuntimeConfig().public.domain

    return joinURL(domain, path)

}

export function routeValidation( route ){

    const start_with = route.params.start_with

    if(['instock_earring___in', 'instock_ring___in', 'instock_pendant___in'].includes(route.name)){
        return false
    }
    
    if (!start_with) {
        return true
    }

    if(['loose-listing___us', 'loose-listing___in'].includes(route.name)){
        const shapes = ['round-cut-diamond', 'pear-cut-diamond', 'oval-cut-diamond', 'emerald-cut-diamond', 'cushion-cut-diamond', 'princess-cut-diamond', 'radiant-cut-diamond', 'heart-cut-diamond', 'asscher-cut-diamond', 'marquise-cut-diamond', 'gia-certified', 'igi-certified']
        return shapes.includes(start_with) ? true : false
    }

    if (['cyo-diamond-list-ring___in', 'cyo-diamond-list-ring___us'].includes(route.name)) {

        const shapes = ['round-rings', 'pear-rings', 'oval-rings', 'emerald-rings', 'cushion-rings', 'princess-rings', 'radiant-rings', 'heart-rings', 'asscher-rings', 'marquise-rings']

        return shapes.includes(start_with) ? true : false
    }

    if (['shop-all___us'].includes(route.name)) {

        const gifts = ['gifts-under-1000', 'gifts-under-2000', 'gifts-under-3000']
        return gifts.includes(start_with) ? true : false


    }

    if (['shop-all___in'].includes(route.name)) {

        const gifts = ['gifts-under-50000', 'gifts-under-80000', 'gifts-under-100000']

        return gifts.includes(start_with) ? true : false
    }

    if (['cyo-setting-list-ring___in', 'cyo-setting-list-ring___us'].includes(route.name)) {

        const settingMetals = [
            'white-gold-engagement-rings',
            'yellow-gold-engagement-rings',
            'rose-gold-engagement-rings',
            'platinum-engagement-rings'
        ]

        const styles = [
            'solitaire-engagement-rings',
            'three-stone-engagement-rings',
            'side-stone-engagement-rings',
            'halo-engagement-rings',
            'vintage-engagement-rings'
        ]


        return settingMetals.includes(start_with) || styles.includes(start_with) ? true : false
    }

}