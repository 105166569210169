<template>
    <div class="sub_menu_parent">
        <NuxtLink @click="$emit('hideShowMenu')" class="for_desktop_mod hover_cta" :to="localePath('/engagement-rings')">Engagement Rings </NuxtLink>
        <div class="for_mobile_mod" @click="toggleMenu()">
            <div class="link_with_arrow">
                <span>Engagement Rings</span>
                <SvgHeaderSvg v-show="!isToggle" :type="'down'" />
                <SvgHeaderSvg v-show="isToggle" :type="'up'" />
            </div>
        </div>
        <div ref="main_menu_box" class="main_menu_box" :class="{ 'open_for_desktop' : hoverFlag === 'engagement',}">
            <div class="inner_menu">
                <div class="landing_link inner_menu_box for_mobile_mod">
                    <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/engagement-rings')">Engagement Rings</NuxtLink>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Create your own</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring'})">
                                <SvgJewelrySvg :type="'cyor'" />&nbsp;Start with a setting
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring'})">
                                <SvgJewelrySvg :type="'diamond'" />&nbsp;Start with a diamond
                            </NuxtLink>
                          
                        </div>
                        
                    </div>
                    <div class="menu_card">
                        <h3>pre-designed engagement rings</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'preset-listing', query:{ 'Shape': 'Round', 'RefreshKey': 'Round'} })">Round Pre-Designed Rings
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'preset-listing', query:{ 'Shape': 'Emerald', 'RefreshKey': 'Emerald' } })">Emerald Pre-Designed Rings
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')"  :to="localePath({ name:'preset-listing', query:{ 'Shape': 'Princess', 'RefreshKey': 'Princess'} })">Princess Pre-Designed Rings
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')"  :to="localePath({ name:'preset-listing', query:{ 'Shape': 'Oval', 'RefreshKey': 'Oval'} })">Oval Pre-Designed Rings
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" class="view_all_cta" :to="localePath({ name: 'preset-listing', 'RefreshKey': 'all'})">View All</NuxtLink> 
                        </div>
                    </div>
                    <div class="menu_card" v-if="!$country.is_india">
                        <h3>Ready to Ship Engagement Rings</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'instock_ring', query:{'Shape': 'Round', 'RefreshKey': 'Round'}})">Round Cut Rings</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'instock_ring', query:{'Shape': 'Oval', 'RefreshKey': 'Oval'}})">Oval Cut Rings</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" class="view_all_cta" :to="localePath({ name:'instock_ring', 'RefreshKey': 'all'})">View All</NuxtLink> 
                        </div>
                    </div>  
                   
                    <!--  -->
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Shop by shape</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'round-rings' } })">
                                <SvgShapeSvg  :type="'round'" />&nbsp;&nbsp;Round
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'oval-rings' } })">
                                <SvgShapeSvg  :type="'oval'" />&nbsp;&nbsp;Oval
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'pear-rings' } })">
                                <SvgShapeSvg  :type="'pear'" />&nbsp;&nbsp;Pear
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'cushion-rings' } })">
                                <SvgShapeSvg  :type="'cushion'" />&nbsp;&nbsp;Cushion
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'princess-rings' } })">
                                <SvgShapeSvg  :type="'princess'" />&nbsp;&nbsp;Princess
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'radiant-rings' } })">
                                <SvgShapeSvg  :type="'radiant'" />&nbsp;&nbsp;Radiant
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'emerald-rings' } })">
                                <SvgShapeSvg  :type="'emerald'" />&nbsp;&nbsp;Emerald
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'heart-rings' } })">
                                <SvgShapeSvg  :type="'heart'" />&nbsp;&nbsp;Heart
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'asscher-rings' } })">
                                <SvgShapeSvg  :type="'asscher'" />&nbsp;&nbsp;Asscher
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', params:{ start_with: 'marquise-rings' } })">
                                <SvgShapeSvg  :type="'marquise'" />&nbsp;&nbsp;Marquise
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-diamond-list-ring', query: { 'FieryRound': 'Yes', 'RefreshKey': 'fiery-round' } })">
                                <SvgShapeSvg  :type="'fiery_round'" />&nbsp;&nbsp;Fiery Rounds
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Shop by style </h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'solitaire-engagement-rings' } })">
                                <SvgStyleSvg  :type="'solitaire'" />&nbsp; Solitaire
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'three-stone-engagement-rings' } })">
                                <SvgStyleSvg  :type="'three_stone'" />&nbsp; Three Stone
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'side-stone-engagement-rings' } })">
                                <SvgStyleSvg  :type="'side_stone'" />&nbsp; Side Stone
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'halo-engagement-rings' } })">
                                <SvgStyleSvg  :type="'halo'" />&nbsp; Halo
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'vintage-engagement-rings' } })">
                                <SvgStyleSvg  :type="'vintage'" />&nbsp; Vintage
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3>Shop by metal</h3>
                        <div class="menu_ordered">
                           
                            <NuxtLink @click="$emit('hideShowMenu')"   :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'white-gold-engagement-rings' } })">
                                <!-- <SvgMetalSvg  :type="'white'" /> -->
                                <img width="40" height="40" src="~/assets/metals/white.png" alt="white gold">
                                &nbsp;&nbsp;White Gold
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')"  :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'yellow-gold-engagement-rings' } })">
                                <img width="40" height="40" src="~/assets/metals/yellow.png" alt="yellow gold"/>
                                <!-- <SvgMetalSvg  :type="'yellow'" /> -->
                                &nbsp;&nbsp;Yellow Gold
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')"  :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'rose-gold-engagement-rings' } })">
                                <!-- <SvgMetalSvg  :type="'rose'" /> -->
                                <img width="40" height="40" src="~/assets/metals/rose.png" alt="rose gold"/>
                                &nbsp;&nbsp;Rose Gold
                            </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'cyo-setting-list-ring', params:{ start_with: 'platinum-engagement-rings' } })">
                                <!-- <SvgMetalSvg  :type="'white'" /> -->
                                <img width="40" height="40" src="~/assets/metals/white.png" alt="platinum"/>
                                &nbsp;&nbsp;Platinum
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="inner_menu_box inner_menu_four">
                    <div class="menu_card">
                        <h3>Toi Et Moi Rings</h3>
                        <div class="menu_ordered">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name:'toi-et-moi-ring'})">
                                <SvgJewelrySvg :type="'toi-moi'" />&nbsp;Two Stone Rings
                            </NuxtLink>
                        </div>
                    </div>
                    <div class="menu_card">
                        <h3>Customer Created Inspirations </h3>
                        <div class="menu_ordered menu_ordered_noicon">
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'recently-purchase-carat', params:{slug : '1'}})">1 ct engagement rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'recently-purchase-carat', params:{slug : '2'}})">2 ct engagement rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'recently-purchase-carat', params:{slug : '3'}})">3 ct engagement rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'recently-purchase-shape', params:{slug : 'round'}})">Round engagement rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath({ name: 'recently-purchase-shape', params:{slug : 'oval'}})">Oval engagement rings </NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" class="view_all_cta" :to="localePath({ name: 'recently-purchase-all'})">View All</NuxtLink> 
                        </div>
                    </div>
                    
                    <div class="menu_card">
                        <div class="menu_ordered menu_ordered_mod2">
                            <!-- <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/')">Top 20 rings/Best Seller Rings</NuxtLink> -->
                            <!-- <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/')">Unique Engagement Rings (new page)</NuxtLink> -->
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/customized-jewelry')">Bespoke {{ $t('customize') }}d {{ $t('jewelry') }}</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/how-to-find-ring-size')">Find your ring size</NuxtLink>
                            <NuxtLink @click="$emit('hideShowMenu')" :to="localePath('/engagement-ring-guide')">Engagement Ring Guide</NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const props = defineProps([
      'openOverlay',
      'hoverFlag',
      'locale',
      'subMenuState',
      'menuName'
    ]);
    
    const hideMenu = ref(false);
    const openMainMenu = ref(false);
    const arrow = ref(true);
    const main_menu_box = ref(null)
    const toggleState = toRef(props.subMenuState)

    const isToggle = computed(()=>{
        return toggleState.value[props.menuName]
    })

    const route = useRoute();
    const emit = defineEmits(['hideMenuInDesktop','handleSubMenuState', 'hideShowMenu'])
    
    
    // watch(route, (to, from) => {
    //   hideMenu.value = true;
    //   setTimeout(() => {
    //     hideMenu.value = false;
    //   }, 500);
    //   emit('hideMenuInDesktop');
    // });
    
    watch(() => props.openOverlay, (newVal, oldVal) => {
      if (!oldVal) {
        // arrow.value = true;
        // openMainMenu.value = false;
        emit('handleSubMenuState','');
      }
    });

    watch(isToggle,(oldVal,newVal)=>{
        nextTick(()=>{
                main_menu_box.value.style.display  = isToggle.value ? 'block' : 'none' 
          })
    })
    
    const toggleMenu = () => {
    //   arrow.value = !arrow.value;
    //   openMainMenu.value = !openMainMenu.value;
    emit('handleSubMenuState','engagement');
    };
    
</script>

<style scoped>
.landing_link .inner_menu_box{
    padding: 10px 0 !important;
} 
.main_menu_block ul li a {
    color: #303030;
    font-size: 16px;
    line-height: 1.25;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.inner_menu_box .menu_card{
    border-top: none;
}

.menu_ordered{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

@media (max-width: 1024px){
    .inner_menu_box .menu_card {
        margin-bottom: 8px !important;
    }
}
</style>