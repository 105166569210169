<template>
    <div class="trustpilot_box ">
        <div class="trust_intro_flex">
            <div class="trust_title">
                <div class="trust_icon">
                    <img width="144" height="31" src="~/assets/assets/trustpilotlogo.svg" alt="trustpilot logo svg">
                </div>
                <div class="para">
                    <p class="">Trustpilot lets customers review <br> businesses they’ve experienced</p>
                </div>
            </div>
            <div class="trust_count">
                <div class="star_box mb-2">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="40" height="40" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="35" height="35" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                    <img width="30" height="30" src="~/assets/assets/trustpilot.svg" alt="trustpilot logo svg">
                </div>
                <h3 class="mb-1">Excellent</h3>
                <a rel="nofollow noopener" target="_blank"
                    href="https://www.trustpilot.com/review/friendlydiamonds.com">600+ reviews</a>
            </div>
        </div>

        <div class="trust_review_block">
            <div class="box2">
                <swiper ref="c1" :modules="[SwiperAutoplay]" v-bind="swiperSettings" class="trustpilot__swiper">
                    <swiper-slide v-for="item in trustpilot" :key="item.id" class="py-3">
                        <div class="slide_box h-100">
                            <TrustpilotReviewBox :data="item" />
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
// const skeleton = ref(true);

const trustpilot = [
    {
        id: 1,
        name: 'Eddie kalantarzadeh',
        date: 'Nov 25, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Amazing service',
        description: 'The engagement ring that I had ordered showed up weeks ahead of time and it honestly looked so much more amazing in person…'
    },
    {

        id: 2,
        name: 'Valerie V',
        date: 'Nov 26, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'They exceeded my expectations',
        description: 'They exceeded my expectations, my ring is even more breathtaking in person! I highly recommend for anyone looking for high quality jewelry!...'
    },
    {

        id: 3,
        name: 'Michael Williams',
        date: 'Dec 4, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Really good customer service',
        description: 'Really good customer service. I requested the ring be completed and delivered by a specific date and it arrived early.'

    },
    {
        id: 4,
        name: 'Curt',
        date: 'Dec 5, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Proactive consultation',
        description: 'Proactive consultation, superb flexibility, and overall excellent customer service, along with great products.'
    },
    {
        id: 5,
        name: 'Justin Cummings',
        date: 'Dec 9, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'The company was very responsive to my…',
        description: 'The company was very responsive to my questions. They also took the initiative to reach out to me because of minor delays…'
    },
    {

        id: 6,
        name: 'Michael S',
        date: 'Dec 10, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'I love my new ring',
        description: 'I love my new ring. The staff is wonderful and made the process very smooth. I could understand their suggestions which made the purchase…'

    },
    {
        id: 7,
        name: 'Kellie Clark',
        date: 'Dec 18, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Yes! Yes! Yes!',
        description: 'Stellar transaction from start to finish. My first lab diamond purchase is a hit!!!! I purchased the 3 carat toi et moi ring, “Cecelia”.…'

    },
    {
        id: 8,
        name: 'Jeff Sparling',
        date: 'Dec 18, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'the ring was beautiful',
        description: 'The ring was beautiful and the presentation box was incredible thank you so much.'

    },
    {
        id: 9,
        name: 'Darlene Oeast',
        date: 'Dec 22, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'It was beautiful',
        description: 'It was beautiful and the sizing was perfect. My daughter loved it and is now officially engaged.'

    },
    {
        id: 10,
        name: 'K.Osborne',
        date: 'Dec 26, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Definitely 5 stars',
        description: 'The ring and diamond are beautiful. Great craftsmanship.The wife definitely wasn’t expecting that for Christmas. She absolutely loved it…'

    },
    {
        id: 11,
        name: 'G. S.',
        date: 'Dec 26, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'Easy payments and great product',
        description: 'I was able to do an Affirm payment plan which made things easier to pay over time. The Jewelry arrived on time and looks great..…'

    },
    {
        id: 12,
        name: 'Desepeda Maureen',
        date: 'Dec 26, 2024',
        // place: 'US',
        rating: 5,
        verified: true,
        title: 'I recently bought a 1ct emerald cut',
        description: 'I recently bought a 1ct emerald cut stud earrings in WG and it was so stunning and because of that I purchased 4ct emerald cut earrings...…'

    }
]

const swiperSettings = reactive({
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    breakpoints: {
        0: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
        },
        512: {
            slidesPerView: 2,
            slidesPerGroup: 1,
        },
        901: {
            slidesPerView: 2.5,
            slidesPerGroup: 1,
        },
        1025: {
            slidesPerView: 3.5,
            slidesPerGroup: 1,
        },
    },
});

</script>

<style scoped>
:global(.trustpilot__swiper .swiper-slide) {
    height: auto !important;
}

.trustpilot_box {
    display: flex;
    margin-top: 4%;
}


.trust_review_block {
    display: flex;
}


.trust_intro_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 20%;
    margin-right: 15px;
}

.trust_review_block {
    width: 80%;
}

.box2 {
    width: 100%;
}

.trust_title {
    position: relative;
}

.para p br {
    display: none;
}

.para {
    border-bottom:  2px solid #eaeaea;
}

.trust_count,
.trust_title {
    padding: 0 20px;
}

.trust_title .trust_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.trust_title h2 span {
    display: inline-block;
    margin: 10px 0 0 5px;
    font-weight: 600;
    color: #191919;
}

.trust_title p {
    color: #606060;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 10px;
    text-align: center;
}

.star_box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.trust_count h3 {
    font-size: 20px;
    color: #212529;
    text-align: center;
    font-weight: 600;
}

.trust_count a {
    display: inline-block;
    color: #353535;
    text-decoration: underline;
}

.trust_count {
    text-align: center;
    margin-top: 12px;
}


.slide_box {
    padding: 2px;
}


@media(max-width:1200px){
    .trust_count,
    .trust_title {
        padding: 0 10px;
    }
}


@media(max-width:992px) {
    

    .trust_intro_flex {
        width: 25%;
    }

    .trust_review_block {
        width: 75%;
    }
}


@media(max-width:767px) {
    .trustpilot_box{
        flex-direction: column;
        align-items: center;
    }

    .trust_intro_flex {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-right: 0;
    }

    .trust_count h3 {
        font-size: 24px;
    }

    .trust_title{
        border-right: 1px solid #eaeaea;
        border-bottom: none;
    }

    .trust_title p {
        color: #353535;
        font-size: 16px;
        line-height: normal;
    }

    .para {
        border-bottom: none;
    }

    .para p br {
        display: block;
    }

    .trust_count,
    .trust_title {
        padding: 0 40px;
    }

    .trust_count {
        margin-top: 0;
    }
    
    .trust_review_block {
        width: 100%;
    }

    .trust_review_block {
        margin-top: 4%;
    }
}

@media(max-width: 600px) {
   

    .trust_title h2 {
        justify-content: center;
    }

    
    .trust_title p {
        text-align: center;
        font-size: 12px;
    }

  
    .trust_count h3 {
        font-size: 18px;
    }


    .trust_title {
        padding: 0 10px 0 0;
    }
 
    .trust_count a{
        font-size: 12px;
    }
    .trust_count {
        margin-left: 10px;
    }
    .trust_title p br {
        display: none;
    }
}




</style>