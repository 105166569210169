<template>
    <div class="email_modal" v-if="showPopup">
        <div class="model_block" >
            <div class="email_body">
                <div class="main_box" v-if="!success">
                    <div class="img_left">
                        <img width="592" height="867" src="https://assets.friendlydiamonds.com/fdl/fd_gif/model.jpg" alt="model">
                    </div>

                    <div class="right_form">
                        <div class=" text-center">
                            <!-- <div>
                                <h3 class="mb-3 font-active">Save Big: Get $50 Off* Your Purchase</h3>
                                <p>Subscribe now and receive the Special Promo Code!</p>
                            </div> -->
                            <div class="new_title">
                                <h5>SAVE BIG:</h5>
                                <h3 class="font-active">GET <span>$50</span> OFF</h3>
                                <h5>ON YOUR PURCHASE</h5>
                            </div>

                            <span class="upto_medium" @click="hideModal">
                                <!-- <SvgHeaderSvg :type="'close'" class="largesvg"/> -->
                                <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.375 14.375L5.625 5.625M14.375 5.625L5.625 14.375" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                            </span>
                        </div>
                        <form action="" @submit.prevent="Submit()">
                            <div class="column first_input">
                                <input name="your_name" :required="true" id="name" type="text" v-model="formData.name"
                                    :class="{ 'input-style': true }"
                                    @input="(event) => handleInput('name', event.target.value)" placeholder="Your Name" />

                                    <!-- <input name="your_name" :required="true" id="name" type="text" v-model="formData.name"
                                    @input="(event) => handleInput('name', event.target.value)" placeholder="Your Name" /> -->

                                <span v-if="errorMessage.name" class="error-msg">{{ errorMessage.name.join(" ") }}</span>
                            </div>
                            <div class="column sec_input">

                                <input name="email" :required="true" id="email" type="email" v-model="formData.email"
                                    :class="{ 'input-style': true }"
                                    @input="(event) => handleInput('email', event.target.value)" placeholder="Email Id" />
                                    <!-- <input name="email" :required="true" id="email" type="email" v-model="formData.email"
                                    @input="(event) => handleInput('email', event.target.value)" placeholder="Email Id" /> -->

                                <span v-if="errorMessage.email" class="error-msg">{{ errorMessage.email.join(" ") }}</span>
                            </div>

                            <div class="t_c">
                                <p>Sign up to receive offers and updates from Friendly Diamonds. Unsubscribe anytime.</p>
                                <p class="mb-0">*Minimum purchase of $1,000 required; cannot be combined with other offers.</p>
                            </div>


                            <div class="d-flex gap-2">
                                <CommonBasePrimary type="button" class="decline-btn flex-1" @click="hideModal">No, THANKS</CommonBasePrimary>
                                <CommonBasePrimary disabled v-if="showLoader" class="flex-1 please-wait-btn">please wait...</CommonBasePrimary>
                                <CommonBasePrimary v-else type="submit" class="flex-1 sub-btn">SUBMIT</CommonBasePrimary>
                            </div>
                            <!-- <p class="success mb-0" v-if="success">{{ success }}</p> -->
                        </form>
                        <!-- <div class="title_block mt-2">
                            <p class="mb-0">*Minimum purchase value of $1000. Cannot be clubbed with another offer.</p>
                        </div> -->
                    </div>

                </div>
                <div v-else class="success">
                    <div class="main_box_mod">
                        <div class="img_left img_left_mod">
                        <img width="592" height="867" src="https://assets.friendlydiamonds.com/fdl/fd_gif/model.jpg" alt="model">
                        </div>
                        <div class="right_form_mod">
                            <div class="title_block">
                      
                                    <h3 class="text-center font-active">Congratulations!</h3>
                                    <p class="text-center">You’ve unlocked a <br class="one">Special Promo <br class="two">Code for your purchase.</p>
                                    <!-- <p>Use this code during checkout:</p> -->
            
                                <span class="upto_medium" @click="hideModal">
                                    <!-- <SvgHeaderSvg :type="'close'" class="largesvg"/> -->
                                    <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.375 14.375L5.625 5.625M14.375 5.625L5.625 14.375" stroke="#C4C4C4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </span>
                            </div>
                            <div class="promoCode">
                                <!-- <h6>Promo Code</h6> -->
                                <div class="promo_flex">
                                    <div class="promo_text font-active" id="promo_text">
                                        {{ copyText }}
                                    </div>
                                    <div class="copy_btn" @click="copyToClipboard">
                                        <span v-if="isCopied">Copied!</span>
                                        <!-- <img src="@/assets/copy.svg" alt="copy to clipboard"> -->
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7188 4.5H5.78125C4.79749 4.5 4 5.39718 4 6.50391V14.3086C4 15.4153 4.79749 16.3125 5.78125 16.3125H12.7188C13.7025 16.3125 14.5 15.4153 14.5 14.3086V6.50391C14.5 5.39718 13.7025 4.5 12.7188 4.5Z" stroke="#c4c4c4" stroke-linejoin="round"/>
                                            <path d="M11.9844 4.5L12 3.65625C11.9987 3.13456 11.8139 2.63466 11.486 2.26577C11.1581 1.89688 10.7137 1.68898 10.25 1.6875H3.5C2.97005 1.68926 2.46225 1.92688 2.08752 2.34846C1.71278 2.77003 1.50157 3.3413 1.5 3.9375V11.5312C1.50132 12.0529 1.68612 12.5528 2.01402 12.9217C2.34192 13.2906 2.78628 13.4985 3.25 13.5H4" stroke="#c4c4c4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { popupStore } from '@/stores/popup/popup';
const store = popupStore()
const showPopup = ref(false)
const showLoader = ref(false)
const formData = ref({
    name: '',
    email: ''
})

const success = ref(false)
const errorMessage = ref({})
const copyText = ref('FD50')
const isCopied = ref(false)
const module = ref('subscribe')

const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = copyText.value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    isCopied.value = true
}

const handleInput = (key, value) => {
    formData.value[key] = value
}

const showModal = () => {
    // this.$refs['my-modal'].show()
}

const hideModal = () => {
    showPopup.value = false
}

const hideMsg = () => {
    setTimeout(() => {
        success.value = false
    }, 3000);
}

const Submit = async () => {
    try {
        showLoader.value = true
        const res = await $fetch(useCountryPath('/subscribe/from-popup'), {
            method: 'POST',
            body: formData.value
        })

        if (res.success) {
            // message = false;
            errorMessage.value = false;
            showLoader.value = false
            success.value = res.message
        }

    } catch (error) {
        showLoader.value = false
        if (error.response !== undefined && error.response.status === 422) {
            errorMessage.value = error?.response?._data?.errors
            // showLoader.value = false
            return;
        }
    }

}


onMounted(async () => {

    const status = await store.getPopupFlag(module.value)
    if (!status) {
        setTimeout(() => { showPopup.value = true }, 10000)
        store.setPopupFlag(module.value)
    }
})

</script>

<style scoped>
.main_box {
    display: flex;
    width: 100%;
    /* background: linear-gradient(to bottom, #aed8fd, #b4c3ec); */
}

.img_left {
    width: 50%;
}

.right_form {
    width: 50%;
    /* background: #21324db3 70%; */
    background-image: linear-gradient(to bottom, #8b1414 0%, #570909);
    position: relative;
    padding: 24px;
    /* border-radius: 4px; */
    overflow: hidden;
    /* border-radius: 8px; */
}

.img_left img {
    border-radius: 8px 0 0 8px;
}

::placeholder {
    color: #c4c4c4;
    font-size: 14px;
    background: transparent;
}

input[type="text"]::placeholder {
    color: #c4c4c4;
    font-size: 14px;
}

input[name="email"]::placeholder {
    color: #c4c4c4;
    font-size: 14px;
}

input[type="text"],
input[type="email"] {
    background-color: transparent;

}

input[type="text"].filled,
input[type="email"].filled {
    background-color: transparent;
}

.upto_medium {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 5px;
}

.new_title {
    color: #fff;
    margin: 32px 20px;
}

.title_block .one {
    display: none;
}

.new_title h5 {
    font-size: 14px;
    letter-spacing: 1.5px;
    margin-bottom: 4px;
}

.new_title h3 {
    font-size: 28px;
    margin-bottom: 4px;
}

.new_title h3 span {
    font-style: italic;
}

.new_title h6 {
    font-size: 14px;
    letter-spacing: 1.5px;
}

.t_c {
    margin: 20px 0;
}

.column.first_input {
    margin: 20px 0 8px 0;
}

.t_c p {
    font-size: 12px;
    color: #eaeaea;
    margin-bottom: 6px;
}

.sub-btn , .please-wait-btn{
    background: #fff !important;
    color: #000;
    letter-spacing: 1.5px;
}

input#email {
    color: #c4c4c4;
    font-size: 14px;
    background: transparent;
}

input#name {
    color: #c4c4c4;
    font-size: 14px;
    background: transparent;
    
}

/* .first_input {
    margin-bottom: 8px;
} */

/* .sec_input {
    margin-bottom: 20px;
}
 */

.main_box_mod {
    display: flex;
    align-items: center;
    background-image: linear-gradient(to bottom, #8b1414 0%, #570909);

    /* background: #21324db3 70%; */
    /* border-radius: 4px; */
}

.right_form_mod {
    width: 50%;
    max-width: 250px;
    margin: 0 auto;
}

.decline-btn {
    color: #fff;
    background: transparent !important;
    border: 1px solid #fff;
}

button {
    border-radius: 4px;
}

.largesvg {
    transform: scale(1.3);

}

p.success {
    text-align: center;
    margin-top: 5px;
}

video {
    max-width: 100%;
    height: auto;
}

.error {
    color: rgb(255, 0, 0);
}

.copy_btn span {
    font-size: 12px;
    font-weight: 500;
    color: #ffff;
    margin: 0 5px 0 0;
}

.model_block {
    width: 90%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
}

.modal_img img {
    border-radius: 4px 0 0 4px;
}

.email_body {
    /* padding: 26px; */
    position: fixed;
    bottom: 30px;
    left: 30px;
    background: #fff;
    width: 650px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;
    border-radius: 8px;
    overflow: hidden;
}

.title_block {
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */

}

.title_block h3 {
    font-size: 20px;
    text-align: left;
}

.title_block p {
    font-size: 14px;
    color: #ffff;
    margin-bottom: 12px;
}

.title_block span {
    cursor: pointer;
}

span.below_medium {
    display: none;
}

.promo_flex {
    border: 1px dashed #c4c4c4;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h6 {
    color: #353535;
    font-size: 14px;
    font-weight: 800;
}

.promo_text {
    font-size: 14px;
    color: #ffff;
    letter-spacing: 0.5px;
}

.copy_btn {
    cursor: pointer;
    height: 27px;
}

.title_block h3 {
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 32px;
}

@media(max-width: 1200px) {
    .modal_img img {

        width: 100%;
    }
}

/* @media(max-width: 1200px) {
    .model_block img {
        height: 100%;
        object-fit: cover;
    }

    .modal_img {
        position: relative;
        height: 100%;
    }

    .model_block {
        width: 100%;
    }

    .title_block h3 {
        font-size: 24px;
    }

    .email_body {
        padding: 7%;
    }

    .email_body {
        padding: 20px;
        background: #fff;
    }

    .col-md-12 {
        padding: 0 !important;
    }

    .modal_img img {
        border-radius: 5px 5px 0 0;
    }

    .title_block p {
        font-size: 14px;
    }
} */

@media(max-width: 992px) {
    .modal_img span.below_medium {
        position: absolute;
        top: 6px;
        right: 20px;
        display: block;
    }

}

@media (max-width:767px) {
    .img_left {
        display: none;
    }
  .main_box_mod {
        padding: 20px 16px;
    }
    .new_title {
        color: #fff;
        margin: 0;
    }
    .img_left.img_left_mod {
        display: none;
    }

    .right_form {
        width: 100%;
    }

    .right_form_mod {
        width: 100%;
        /* padding: 20px 16px 32px; */
    }

    .title_block .one {
        display: block;
    }

    .title_block .two {
        display: none;
    }

    .title_block p {
        font-size: 16px;
    }

    .email_body {
        max-width: 380px;
        border-radius: 8px;
    }
    .right_form_mod {
        width: 100%;
        margin: 0 auto;
    }

    .email_body {
        /* border-radius: 16px 16px 0 0; */
        border-radius: 8px;

    }

}

@media(max-width: 600px) {
    .email_body {
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 99999;
        width: 90%;
    }

    .modal_img span.below_medium {
        top: 0px;
        right: 8px;
    }

    .model_block {
        width: 85%;
        margin: 0 auto;
    }

    .title_block h3 {
        font-size: 20px;
        margin-bottom: 7px !important;
    }

    .title_block p {
        font-size: 14px;
    }
}

.input-style {
    color: #eaeaea;
    background-color: transparent;
    font-size: 14px;
}

.input-style[type="email"].filled {
    background-color: transparent;
}

::placeholder {
    color: #c4c4c4;

}

input[type="text"]:focus,
input[type="email"]:focus {
    outline: none;
    background-color: transparent;
    color: #eaeaea;
}
</style>
